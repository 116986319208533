import React, { useState, useEffect,Suspense } from 'react'
import { Amplify, Auth, Hub, Cache } from 'aws-amplify'
import { Switch, Route } from 'react-router-dom'
import AuthRouter from '../Global/AuthRouter'
import { ApiProvider } from '../Contexts/ApiContext'
import { Routes, RouteList } from '../Global/Routes'
// import Layout from '../Global/Layout'
import ScrollToTop from '../Global/ScrollToTop'
import Analytics from '../Global/Analytics'
import awsConfig from '../aws-exports'
// import ErrorBoundary from '../Containers/ErrorBoundary'
// import BadRequestPage from '../Components/BadRequestPage'
import IdleTimer from '../Components/auth/IdleTimerContainer'
import TimeoutModal from '../Components/auth/TimeoutModal'
import { logOut } from '../services/auth'
import { SiteUnderMaintenancePage } from '../Components/SiteUnderMaintenance'
import UnsupportedBrowserPage from '../Components/UnsuportedBrowserPage'
import { datadogRum } from '@datadog/browser-rum'
import { Authenticator } from '@aws-amplify/ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
// import LayoutModern from '../Global/LayoutModern'
import { useLocation } from 'react-router-dom'
import LoadingPage from '../Components/LoadingPage'
const ErrorBoundary = React.lazy(() => import('../Containers/ErrorBoundary'))
const BadRequestPage = React.lazy(() => import('../Components/BadRequestPage'))
const Layout = React.lazy(() => import('../Global/Layout') )
const LayoutModern= React.lazy(() => import('../Global/LayoutModern') )
const CommonQuestions = React.lazy(() => import('../Components/CommonQuestions'));
const CommonForms = React.lazy(() => import('../Containers/CommonFormsContainer'));
const Contact = React.lazy(() => import('../Components/Contact'));
const PrivacyPolicy = React.lazy(() => import('../Components/PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('../Components/TermsAndConditions'));
const Configs = React.lazy(() => import('../Containers/Configs'));
const AccessibilityStatement = React.lazy(() => import('../Components/AccessibilityStatement'));
const ScheduleOfFees = React.lazy(() => import('../Components/ScheduleOfFees'));
const Licenses = React.lazy(() => import('../Components/Licenses'));
const ImportantDisclosures = React.lazy(() => import('../Components/ImportantDisclosures'));
const Claim = React.lazy(() => import('../Components/Claim'));
const HelpfulResources = React.lazy(() => import('../Components/HelpfulResources'));
const Payment = React.lazy(() => import('../Components/Payment'));

Amplify.configure(awsConfig)

const getResetTimerState = () => ({ isTimedOut: false, timer: null })

const getTimeUntilLogoutAlert = () =>
  process.env.REACT_APP_TIME_UNTIL_LOGOUT_ALERT || 840

const getTimeUntilLogoutAfterAlert = () =>
  process.env.REACT_APP_TIME_UNTIL_LOGOUT_AFTER_ALERT || 60

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    Cache.clear()
    localStorage.removeItem('selectedTab')
  }
})

const App = withLDConsumer()(({ flags }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [timer, setTimer] = useState(getResetTimerState())
  const [lastActive, setLastActive] = useState(null)
  const { pathname } = useLocation()

  const modernRoutes = ['/', '/common-questions', '/common-forms', '/helpful-resources', '/contact', '/payment']

  const updateAuthenticatedStatus = async () => {
    const userTraits = localStorage.getItem('ajs_user_traits');
    if (!userTraits) return;

    const user = await Auth.currentUserInfo()
    setUserInfo(user)
    setIsAuthenticated(!!user)
  }

  const handleLogout = async () => {
    setLastActive(null)
    setTimer(getResetTimerState())
    await logOut()
  }

  useEffect(() => {
    const checkAuthStatus = async () => {
      updateAuthenticatedStatus()
    }
    checkAuthStatus()
  }, [])

  useEffect(() => {
    if (lastActive && isAuthenticated && timer.isTimedOut) {
      if (
        (new Date().getTime() - lastActive.getTime()) / 1000 >=
        parseInt(getTimeUntilLogoutAlert()) +
        parseInt(getTimeUntilLogoutAfterAlert())
      )
        handleLogout()
    }
  }, [lastActive, isAuthenticated, timer.isTimedOut])

  useEffect(() => {
    // Run! Like go get some data from an API.
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_RUM_SERVICE,
      beforeSend: (event) => {
        if (
          event.error &&
          event.error.message.includes('segment')
        ) {
          return false
        }
      },
    })
  }, []);

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <IdleTimer
            timeInSeconds={getTimeUntilLogoutAlert()}
            onTimeout={(timer) => {
              setTimer({ timer, isTimedOut: true })
              console.log('timed out')

              // Idle timer keeps calling onTimeout as you are idle. We want to record the first time the user is idle and then reset the flag by setting it to null
              if (!lastActive)
                setLastActive(new Date(timer.getLastActiveTime()))
            }}
            onLogout={handleLogout}
          />
          {timer.isTimedOut ? (
            <TimeoutModal
              showModal={timer.isTimedOut}
              handleClose={() => {
                timer.timer.reset()
                setTimer({ ...timer, isTimedOut: false })
                setLastActive(null)
              }}
              handleLogout={() => {
                handleLogout()
              }}
              timeoutInSeconds={getTimeUntilLogoutAfterAlert()}
              lastActive={lastActive}
            />
          ) : (
            <div />
          )}
        </>
      ) : (
        <div />
      )}
      {/* <BrowserRouter> */}
      <UnsupportedBrowserPage>
        <ApiProvider isAuthenticated={isAuthenticated}>
          <ScrollToTop />
          <Analytics
            userInfo={userInfo}
            isAuthenticated={isAuthenticated}
          />
          <Authenticator.Provider>
            {modernRoutes.includes(window.location.pathname)
              ? (
                <Suspense fallback={<div> <LoadingPage authState={!isAuthenticated?'signIn':null} /> </div>}>
                <LayoutModern isAuthenticated={isAuthenticated}>
                <Suspense fallback={<div> <LoadingPage authState={!isAuthenticated?'signIn':null} /> </div>}>
                <Switch>
                    <Route
                      key="/helpful-resources"
                      exact
                      path="/helpful-resources"
                      component={HelpfulResources}
                    />
                    <Route
                      key="/common-questions"
                      exact
                      path="/common-questions"
                      component={CommonQuestions}
                    />
                    <Route
                      key="/common-forms"
                      exact
                      path="/common-forms"
                      component={CommonForms}
                    />                <Route
                      key="/contact"
                      exact
                      path="/contact"
                      component={Contact}
                    />
                    <Route
                      key="/privacy-policy"
                      exact
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    <Route
                      key="/terms-conditions"
                      exact
                      path="/terms-conditions"
                      component={TermsConditions}
                    />
                    <Route
                      key="/schedule-of-borrower-fees"
                      exact
                      path="/schedule-of-borrower-fees"
                      component={ScheduleOfFees}
                    />
                    <Route
                      key="/licenses"
                      exact
                      path="/licenses"
                      component={Licenses}
                    />
                    <Route
                      key="/important-disclosures"
                      exact
                      path="/important-disclosures"
                      component={ImportantDisclosures}
                    />
                    <Route
                      key="/claims"
                      exact
                      path="/claims"
                      component={Claim}
                    />
                    <Route
                      key="/accessibility-statement"
                      exact
                      path="/accessibility-statement"
                      component={AccessibilityStatement}
                    />
                    <Route
                      key="/payment"
                      exact
                      path="/payment"
                      component={Payment}
                    />
                    {RouteList.map(({ path }) => path).some((path) =>
                      path
                        .toLowerCase()
                        .includes(pathname.toLowerCase()),
                    ) ? (
                      <ErrorBoundary>
                        <SiteUnderMaintenancePage>
                          {/* <Authenticator.Provider
                        onStateChange={updateAuthenticatedStatus}
                        hideDefault={true}
                      > */}
                          <AuthRouter isAuthenticated={isAuthenticated} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated}>
                            <Routes />
                          </AuthRouter>
                          {/* </Authenticator.Provider> */}
                        </SiteUnderMaintenancePage>
                      </ErrorBoundary>
                    ) : (
                      <BadRequestPage />
                    )}
                  </Switch>
                  </Suspense>
                </LayoutModern>
                </Suspense>
              )
              : (
                <Suspense fallback={<div> <LoadingPage /> </div>}>
                <Layout isAuthenticated={isAuthenticated}>
                  <Suspense fallback={<div> <LoadingPage /> </div>}>
                  <Switch>
                    <Route
                      key="/helpful-resources"
                      exact
                      path="/helpful-resources"
                      component={HelpfulResources}
                    />
                    <Route
                      key="/common-questions"
                      exact
                      path="/common-questions"
                      component={CommonQuestions}
                    />
                    <Route
                      key="/common-forms"
                      exact
                      path="/common-forms"
                      component={CommonForms}
                    />                <Route
                      key="/contact"
                      exact
                      path="/contact"
                      component={Contact}
                    />
                    <Route
                      key="/privacy-policy"
                      exact
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    <Route
                      key="/terms-conditions"
                      exact
                      path="/terms-conditions"
                      component={TermsConditions}
                    />
                    <Route
                      key="/schedule-of-borrower-fees"
                      exact
                      path="/schedule-of-borrower-fees"
                      component={ScheduleOfFees}
                    />
                    <Route
                      key="/licenses"
                      exact
                      path="/licenses"
                      component={Licenses}
                    />
                    <Route
                      key="/important-disclosures"
                      exact
                      path="/important-disclosures"
                      component={ImportantDisclosures}
                    />
                    <Route
                      key="/claims"
                      exact
                      path="/claims"
                      component={Claim}
                    />
                    <Route
                      key="/accessibility-statement"
                      exact
                      path="/accessibility-statement"
                      component={AccessibilityStatement}
                    />
                    {RouteList.map(({ path }) => path).some((path) =>
                      path
                        .toLowerCase()
                        .includes(pathname.toLowerCase()),
                    ) ? (
                      <ErrorBoundary>
                          <AuthRouter isAuthenticated={isAuthenticated} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated}>
                            <Routes />
                          </AuthRouter>
                      </ErrorBoundary>
                    ) : (
                      <BadRequestPage />
                    )}
                  </Switch>
                  </Suspense>
                </Layout>
                </Suspense>
              )}
          </Authenticator.Provider>
        </ApiProvider>
      </UnsupportedBrowserPage>
    </div>
  )
})

export default App